// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
//require("channels")
// for the charts
require("highcharts")
// the stylesheets will be served trough webpack as well, you need to place them in app/javascript/stylesheets
import '../stylesheets/application'
import '../stylesheets/dashboard.css'
require("bootstrap-datepicker")
import './bootstrap_custom.js'
import '../stylesheets/bootstrap-datepicker.standalone.css'
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// config/webpack/environment.js doet de provideplugin, maar je moet me deze 3 regels ook nog jQuery beschikbaar stellen, anders is $ undefined
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
// to get our graphs working
import Highcharts from 'highcharts';
import addExporting from "highcharts/modules/exporting";
addExporting(Highcharts)
window.Highcharts = Highcharts;
